import React from "react";
import { useNavigate } from "react-router-dom";

export function getApiUrl(api = "") {
  return process.env.REACT_APP_API + api;
}

export function getImgUrl(url) {
  return process.env.REACT_APP_IMGURL + url;
}

export function isAuthedUser() {
  if (
    localStorage.getItem("accessToken") !== undefined &&
    localStorage.getItem("accessToken") !== null
  ) {
    return true;
  } else {
    return false;
  }
}

export function PrintConsole(log) {
  if (process.env.NODE_ENV !== "production") {
    console.log(log);
  }
}

export function getSubDomain() {
  if (window.location.host.split(".")[0] === "admin") {
    return "admin";
  } else if (window.location.host.split(".")[0] === "store") {
    return "store";
  } else if (window.location.host.split(".")[0] === "user") {
    return "user";
  } else {
    return "localhost";
  }
}

export function setCommas(x) {
  if (isNaN(x)) {
    return "";
  } else {
    if (Boolean(x)) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0;
    }
  }
}

export function getNewlines(html) {
  if (!html) return null;

  const lines = html.split("\\n");

  if (lines.length === 0) {
    return html;
  }

  return lines.map((line, index) =>
    React.createElement(
      "span",
      {
        key: index,
      },
      line,
      React.createElement("br")
    )
  );
}

export function includeStr(str, check) {
  if (str.includes(check)) {
    return true;
  } else {
    return false;
  }
}

export function makeAddress(item) {
  if (!item) {
    return;
  }

  var name = item.name,
    region = item.region,
    land = item.land,
    isRoadAddress = name === "roadaddr";

  var sido = "",
    sigugun = "",
    dongmyun = "",
    ri = "",
    rest = "";

  if (hasArea(region.area1)) {
    sido = region.area1.name;
  }

  if (hasArea(region.area2)) {
    sigugun = region.area2.name;
  }

  if (hasArea(region.area3)) {
    dongmyun = region.area3.name;
  }

  if (hasArea(region.area4)) {
    ri = region.area4.name;
  }

  if (land) {
    if (hasData(land.number1)) {
      if (hasData(land.type) && land.type === "2") {
        rest += "산";
      }

      rest += land.number1;

      if (hasData(land.number2)) {
        rest += "-" + land.number2;
      }
    }

    if (isRoadAddress === true) {
      if (checkLastString(dongmyun, "면")) {
        ri = land.name;
      } else {
        dongmyun = land.name;
        ri = "";
      }

      if (hasAddition(land.addition0)) {
        rest += " " + land.addition0.value;
      }
    }
  }

  return [sido, sigugun, dongmyun, ri, rest].join(" ");
}

export function hasArea(area) {
  return !!(area && area.name && area.name !== "");
}

export function hasData(data) {
  return !!(data && data !== "");
}

export function checkLastString(word, lastString) {
  return new RegExp(lastString + "$").test(word);
}

export function hasAddition(addition) {
  return !!(addition && addition.value);
}

export function maxLengthCheck(object) {
  if (object.value.Length > object.max.Length)
    object.value = object.value.slice(0, object.max.Length);
}

export function EmailCheck(text) {
  var regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

  if (text.match(regExp) != null) {
    return true;
  } else {
    return false;
  }
}
export function EngNumCheck(text) {
  let regExp = /^[A-Za-z0-9]+$/;
  if (!text) {
    return false;
  } else if (text.match(regExp) != null) {
    return false;
  } else {
    return true;
  }
}

export const recordPageIndex = ({ search }) => {
  if (!search) {
    return 0;
  }

  return parseInt(search.split("?page=").at(-1));
};

export const isProduction = () => {
  const apiHost = new URL(process.env.REACT_APP_API).hostname;
  if (apiHost === "admin-api.flexday.kr") {
    return true;
  }
  // if (apiHost === "api.admin.flexday.kr") {
  //   return true;
  // }
  return false;
};

const doorSystemStatuses = [
  {
    value: "PREPARING",
    code: 1,
    description: "작동 준비",
  },
  {
    value: "NORMAL",
    code: 2,
    description: "작동 중",
  },
  {
    value: "CLOSED",
    code: 3,
    description: "작동 중지",
  },
];

export const doorSystemStatusConvert = ({ code: codeProp, key: keyProp }) => {
  return doorSystemStatuses.find(
    ({ value, code }) => code === codeProp || value === keyProp
  );
};
